<script>
import domains from "../test_domains.json"; // Adjusted to relative path for build compatibility

export default {
  data() {
    console.log("DomainList.vue - data() initialized");
    console.log("Preloaded domains from test_domains.json:", domains);
    return {
      domains: domains, // Preloaded domains from static file
      searchQuery: "",
    };
  },
  computed: {
    filteredDomains() {
      console.log("DomainList.vue - filteredDomains computation triggered");
      const query = this.searchQuery.trim().toLowerCase();
      console.log("Current normalized search query:", query);

      const filtered = query
        ? this.domains.filter((domain) => {
            const nameMatch = domain.name.toLowerCase().includes(query);
            const keywordMatch =
              Array.isArray(domain.keywords) &&
              domain.keywords.some((keyword) =>
                keyword.toLowerCase().includes(query)
              );
            const descriptionMatch =
              domain.description &&
              domain.description.toLowerCase().includes(query);

            console.log(
              `Domain: ${domain.name}, Name match: ${nameMatch}, Keywords match: ${keywordMatch}, Description match: ${descriptionMatch}`
            );

            return nameMatch || keywordMatch || descriptionMatch;
          })
        : this.domains;

      console.log("Filtered domains count:", filtered.length);

      // Sort domains alphabetically
      const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name));
      console.log("Sorted filtered domains:", sorted.map((d) => d.name));
      return sorted;
    },
  },
  methods: {
    handleSearch() {
      console.log("DomainList.vue - handleSearch triggered");
      console.log("Current search query:", this.searchQuery);
    },
  },
};
</script>

<template>
  <div class="domain-list">
    <!-- Search Bar -->
    <div class="search-bar">
      <input
        v-model="searchQuery"
        placeholder="Search domains..."
        type="text"
        @input="handleSearch"
      />
      <button @click="handleSearch">Search</button>
    </div>

    <!-- Domains -->
    <div v-if="filteredDomains.length > 0" class="domains-container">
      <div
        v-for="domain in filteredDomains"
        :key="domain.name"
        class="domain-item"
      >
        <a
          :href="`/domains/${domain.name}`"
          class="domain-link"
        >
          <h2>{{ domain.name }}</h2>
        </a>
        <p>{{ domain.description }}</p>
      </div>
    </div>
    <div v-else>
      <h3>No domains found.</h3>
    </div>
  </div>
</template>

<style scoped>
.domain-list {
  font-family: Arial, sans-serif;
  padding: 2em;
  text-align: center;
}

.search-bar {
  margin-bottom: 1.5em;
}

.search-bar input {
  padding: 0.5em;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-bar button {
  padding: 0.5em 1em;
  margin-left: 0.5em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #0056b3;
}

.domains-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
  justify-content: center;
}

.domain-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1em;
  width: 250px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.domain-item h2 {
  font-size: 1.2em;
  margin: 0 0 0.5em;
  color: #007bff;
}

.domain-link {
  text-decoration: none;
  color: inherit;
}

.domain-link:hover {
  text-decoration: underline;
}

.domain-item p {
  font-size: 1em;
  color: #555;
}

.domain-item:hover {
  background-color: #f9f9f9;
}

.no-results {
  color: #d9534f;
}
</style>
