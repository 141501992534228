import { createRouter, createMemoryHistory } from "vue-router";
import HomePage from "@/components/HomePage.vue"; // Use alias for cleaner paths
import DomainPage from "@/components/DomainPage.vue"; // Use alias for cleaner paths
import testDomains from "@/test_domains.json"; // Use alias for test domains

// Define static routes
const staticRoutes = [{ path: "/", name: "Home", component: HomePage }];

// Validate and sanitize domains
const validDomains = testDomains.filter((domain, index) => {
  if (!domain.name || typeof domain.name !== "string") {
    console.error(`Invalid domain entry at index ${index}:`, domain);
    return false;
  }
  console.log(`Valid domain at index ${index}:`, domain.name);
  return true;
});

// Log filtered domains
console.log("Total domains in test_domains.json:", testDomains.length);
console.log("Valid domains after filtering:", validDomains.length);

// Generate dynamic domain routes
const domainRoutes = validDomains.map((domain) => {
  const path = `/domains/${encodeURIComponent(domain.name)}`;
  console.log(`Generated route for domain: ${domain.name}, Path: ${path}`);
  return {
    path,
    name: `domain-${domain.name}`,
    component: DomainPage,
    props: { domain },
  };
});

// Log generated routes
console.log("Static Routes:", staticRoutes);
console.log("Dynamic Domain Routes:", domainRoutes);

// Combine all routes
const routes = [...staticRoutes, ...domainRoutes];

// Create the router instance
const router = createRouter({
  history: createMemoryHistory({ base: "/" }),
  routes,
});

// Log final combined routes
console.log("Final Combined Routes:", routes);

// Add a global error handler for navigation
router.onError((error) => {
  console.error("Router navigation error:", error);
});

export { routes };
export default router;
