<template>
  <div class="app">
    <header class="header">
      <!-- Search bar commented out -->
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    console.log("App.vue - data() initialized"); // Debugging
    return {
      searchQuery: "", // User's input in the search bar
      lastProcessedQuery: null, // Tracks the last processed query to avoid redundant updates
    };
  },
  methods: {
    search() {
      console.log("App.vue - search method triggered");
      console.log("Current searchQuery before trimming:", this.searchQuery);

      const trimmedQuery = this.searchQuery.trim();
      console.log("Trimmed searchQuery:", trimmedQuery);

      if (trimmedQuery === this.lastProcessedQuery) {
        console.log("App.vue - Search query unchanged; skipping router push.");
        return;
      }

      this.$router.push({
        name: "Home",
        query: { q: trimmedQuery || undefined },
      });
      console.log("Router updated with query:", trimmedQuery);

      if (!trimmedQuery) {
        console.warn("App.vue - Empty searchQuery submitted.");
      }

      this.lastProcessedQuery = trimmedQuery;
      console.log("App.vue - lastProcessedQuery updated:", this.lastProcessedQuery);
    },
    logState() {
      console.log("=== App.vue Current State ===");
      console.log("searchQuery:", this.searchQuery);
      console.log("Route query.q:", this.$route.query.q);
      console.log("lastProcessedQuery:", this.lastProcessedQuery);
      console.log("=============================");
    },
  },
  created() {
    console.log("App.vue - created lifecycle hook triggered"); // Debugging

    this.searchQuery = this.$route.query.q || "";
    this.lastProcessedQuery = this.searchQuery;
    console.log("Initial query loaded in App.vue:", this.searchQuery);

    if (!this.searchQuery) {
      console.warn("App.vue - No initial query found in route query parameters.");
    } else {
      console.log("App.vue - Initial searchQuery is valid:", this.searchQuery);
    }

    this.logState(); // Log the initial state
  },
  watch: {
    "$route.query.q": {
      handler(newQuery) {
        console.log("App.vue - Watcher triggered for $route.query.q");
        console.log("New query value:", newQuery);

        if (newQuery === this.lastProcessedQuery) {
          console.log("App.vue - Watcher detected no change in query. Skipping update.");
          return;
        }

        this.searchQuery = newQuery || "";
        console.log("searchQuery updated from watcher:", this.searchQuery);

        this.logState();

        if (!this.searchQuery) {
          console.warn("App.vue - Watcher detected empty query.");
        } else {
          console.log("App.vue - Watcher processed valid query:", this.searchQuery);
        }

        this.lastProcessedQuery = this.searchQuery;
        console.log("App.vue - lastProcessedQuery updated by watcher:", this.lastProcessedQuery);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.app {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  background-color: #f8f9fa;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  padding: 0.5em 1em;
  margin-left: 0.5em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}
</style>
